import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import './styles.css';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
	},
}));

const Progress = () => {
	return (
		// <div className="lds-ellipsis blue">
		// 	<div></div>
		// 	<div></div>
		// 	<div></div>
		// 	<div></div>
		// </div>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			style={{
				margin: 'auto',
				display: 'block',
			}}
			width="100px"
			height="100px"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<circle cx="81" cy="50" r="9" fill="#1f7cb5">
				<animate
					attributeName="cx"
					values="81;50"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="cy"
					values="50;81"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="fill"
					values="#1f7cb5;#de8f4c"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
			</circle>
			<circle cx="50" cy="81" r="9" fill="#de8f4c">
				<animate
					attributeName="cx"
					values="50;19"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="cy"
					values="81;50.00000000000001"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="fill"
					values="#de8f4c;#1f7cb5"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
			</circle>
			<circle cx="19" cy="50.00000000000001" r="9" fill="#1f7cb5">
				<animate
					attributeName="cx"
					values="19;49.99999999999999"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="cy"
					values="50.00000000000001;19"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="fill"
					values="#1f7cb5;#de8f4c"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
			</circle>
			<circle cx="49.99999999999999" cy="19" r="9" fill="#de8f4c">
				<animate
					attributeName="cx"
					values="49.99999999999999;81"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="cy"
					values="19;49.99999999999999"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="fill"
					values="#de8f4c;#1f7cb5"
					keyTimes="0;1"
					dur="0.591715976331361s"
					repeatCount="indefinite"
				></animate>
			</circle>
		</svg>
	);
};

export default function AppProgress() {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={true}>
			<Progress />
		</Backdrop>
	);
}

export { Progress };
