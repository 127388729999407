const SET_QUIZZES = 'SET_QUIZZES';
const SET_QUIZ = 'SET_QUIZ';
const UNSET_QUIZ = 'UNSET_QUIZ';
const ADD_QUIZ = 'ADD_QUIZ';
const REMOVE_QUIZ = 'REMOVE_QUIZ';
const EDIT_QUIZ = 'EDIT_QUIZ';

const state = {
	quizzes: [],
	selectedQuiz: '',
};

const reducer = (state_ = state, action) => {
	const { type } = action;
	switch (type) {
		case SET_QUIZZES: {
			return {
				...state_,
				quizzes: action.payload,
			};
		}
		case ADD_QUIZ: {
			const { payload } = action;
			const quizzes = [...state_.quizzes, payload];
			return {
				...state_,
				quizzes,
			};
		}
		case SET_QUIZ: {
			return {
				...state_,
				selectedQuiz: action.payload,
			};
		}
		case UNSET_QUIZ: {
			return {
				...state_,
				selectedQuiz: '',
			};
		}
		case REMOVE_QUIZ: {
			const { payload: id } = action;
			const quizzes = [...state_.quizzes].filter((quiz) => quiz.id !== id);
			return {
				...state_,
				quizzes,
			};
		}
		case EDIT_QUIZ: {
			const { payload } = action;
			const { id } = payload;
			const quizzes = [...state_.quizzes];
			const index = quizzes.findIndex((quiz) => quiz.id === id);
			quizzes.splice(index, 1, payload);
			return {
				...state_,
				quizzes,
			};
		}
		default: {
			throw new Error(`${type} is not defined in quizzes reducer`);
		}
	}
};

export { SET_QUIZZES, SET_QUIZ, UNSET_QUIZ, ADD_QUIZ, REMOVE_QUIZ, EDIT_QUIZ, state, reducer };
