import { useContext } from 'react';
import { useReducer, createContext } from 'react';
import { alertReducer } from '../reducers';

const AlertContext = createContext({
  isVisible: false,
  type: '',
  message: '',
  dispatch: () => {},
});

export const AlertProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    alertReducer.reducer,
    alertReducer.initState
  );

  return (
    <AlertContext.Provider
      value={{
        type: state.type,
        isVisible: state.isVisible,
        message: state.message,
        dispatch,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { dispatch, isVisible, type, message } = useContext(AlertContext);

  return {
    isVisible,
    type,
    message,
    dispatch,
  };
};
