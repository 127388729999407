import { coursesReducer } from '../reducers';

const setCourses = (payload) => {
	return {
		type: coursesReducer.SET_COURSES,
		payload,
	};
};

const addCourse = (payload) => {
	return {
		type: coursesReducer.ADD_COURSE,
		payload,
	};
};

const removeCourse = (payload) => {
	return {
		type: coursesReducer.REMOVE_COURSE,
		payload,
	};
};

const setSelectedCourse = (payload) => {
	return {
		type: coursesReducer.SET_SELECTED_COURSE,
		payload,
	};
};

const unSelectedCourse = (payload) => {
	return {
		type: coursesReducer.UNSELECTED_COURSE,
		payload,
	};
};

const updateCourse = (payload) => {
	return {
		type: coursesReducer.EDIT_COURSE,
		payload,
	};
};

export { setCourses, addCourse, removeCourse, setSelectedCourse, unSelectedCourse, updateCourse };
