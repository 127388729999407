import { alertReducer } from '../reducers';

const showAlert = (payload) => {
  return {
    type: alertReducer.SHOW_ALERT,
    payload,
  };
};

const hideAlert = () => {
  return {
    type: alertReducer.HIDE_ALERT,
  };
};

export { showAlert, hideAlert };
