import { sectionsReducer } from '../reducers';

const setSections = (payload) => {
	return {
		type: sectionsReducer.SET_SECTIONS,
		payload,
	};
};

const addSection = (payload) => {
	return {
		type: sectionsReducer.ADD_SECTION,
		payload,
	};
};

const removeSection = (payload) => {
	return {
		type: sectionsReducer.REMOVE_SECTION,
		payload,
	};
};

const updateSection = (payload) => {
	return {
		type: sectionsReducer.EDIT_SECTION,
		payload,
	};
};

const setSelectedSection = (payload) => {
	return {
		type: sectionsReducer.SET_SECTION,
		payload,
	};
};

const unSelectSection = () => {
	return {
		type: sectionsReducer.UNSET_SECTION,
	};
};

export { setSections, addSection, removeSection, setSelectedSection, updateSection, unSelectSection };
