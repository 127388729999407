import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useAlert } from '../contexts';
import { getConfig } from './services';
import { showAlert } from '../actions/alert';
import { ADDED_SUCCESSFULLY, ERROR_MSG, SERVER_ERROR_MSG, SUCCESS_MSG } from '../common';

export function usePost({ url, handleLoading, doBack }) {
	const { dispatch } = useAlert();
	const { goBack } = useHistory();

	const callback = useCallback(
		async (body) => {
			try {
				handleLoading?.(true);

				const result = await axios.post(url, body, getConfig());

				handleLoading?.(false);

				dispatch(
					showAlert({
						message: ADDED_SUCCESSFULLY,
						type: SUCCESS_MSG,
					})
				);

				if (doBack) goBack();

				return {
					data: result.data.data,
					code: 201,
				};
			} catch (error) {
				handleLoading?.(false);

				dispatch(
					showAlert({
						message: SERVER_ERROR_MSG,
						type: ERROR_MSG,
					})
				);

				return {
					data: null,
					code: error?.response?.status,
				};
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[url]
	);

	return {
		callback,
	};
}
