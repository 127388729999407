import { useCallback, useState } from 'react';
import axios from 'axios';

import { getConfig } from './services';
import { useAlert } from '../contexts';
import { showAlert } from '../actions/alert';
import { ERROR_MSG, SERVER_ERROR_MSG } from '../common';

export function useGet({ url, handleLoading }) {
	const { dispatch } = useAlert();

	const [data, setData] = useState([]);

	const callback = useCallback(
		async (page = 1, param = '', query) => {
			try {
				let queryParams = '';

				if (query && typeof query === 'object') {
					Object.entries(query).forEach(([key, value]) => {
						queryParams += `&${key}=${value}`;
					});
				}

				handleLoading?.(true);

				const result = await axios.get(
					`${url}${param ? `/${param}` : ''}?pageNumber=${page}${queryParams}`,
					getConfig()
				);

				setData(result.data.data);

				handleLoading?.(false);

				return {
					data: result.data.data,
					code: 200,
				};
			} catch (error) {
				handleLoading?.(false);

				dispatch(
					showAlert({
						message: SERVER_ERROR_MSG,
						type: ERROR_MSG,
					})
				);

				return {
					data: null,
					code: error?.response?.status,
				};
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleLoading, url]
	);

	const handleRemove = (index) => {
		const _data = [...data.rows];

		_data.splice(index, 1);

		setData((prevState) => ({
			...prevState,
			rows: _data,
		}));
	};

	return {
		data,
		callback,
		handleRemove,
	};
}
