import { quizzesReducer } from '../reducers';

const setQuizzes = (payload) => {
	return {
		type: quizzesReducer.SET_QUIZZES,
		payload,
	};
};

const addQuiz = (payload) => {
	return {
		type: quizzesReducer.ADD_QUIZ,
		payload,
	};
};

const removeQuiz = (payload) => {
	return {
		type: quizzesReducer.REMOVE_QUIZ,
		payload,
	};
};

const updateQuiz = (payload) => {
	return {
		type: quizzesReducer.EDIT_QUIZ,
		payload,
	};
};

const setSelectedQuiz = (payload) => {
	return {
		type: quizzesReducer.SET_QUIZ,
		payload,
	};
};

const unSelectQuiz = () => {
	return {
		type: quizzesReducer.UNSET_QUIZ,
	};
};

export { setSelectedQuiz, setQuizzes, addQuiz, removeQuiz, updateQuiz, unSelectQuiz };
