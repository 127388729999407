import { homepageReducer } from '../reducers';

const setData = (payload) => {
	return {
		type: homepageReducer.SET_DATA,
		payload,
	};
};

const setClasses = (payload) => {
	return {
		type: homepageReducer.SET_CLASSES,
		payload: {
			rows: payload.data,
			count: payload.data.length,
		},
	};
};

const setInstructors = (payload) => {
	return {
		type: homepageReducer.SET_INSTRUCTORS,
		payload: {
			rows: payload.data,
			count: payload.data.length,
		},
	};
};

const addInstructor = (payload) => {
	return {
		type: homepageReducer.ADD_INSTRUCTOR,
		payload,
	};
};

const removeInstructor = (payload) => {
	return {
		type: homepageReducer.REMOVE_INSTRUCTOR,
		payload,
	};
};

const updateInstructor = (payload) => {
	return {
		type: homepageReducer.UPDATE_INSTRUCTOR,
		payload,
	};
};

const addClass = (payload) => {
	return {
		type: homepageReducer.ADD_CLASS,
		payload,
	};
};

const removeClass = (payload) => {
	return {
		type: homepageReducer.REMOVE_CLASS,
		payload,
	};
};

const updateClass = (payload) => {
	return {
		type: homepageReducer.UPDATE_CLASS,
		payload,
	};
};

const setReports = (payload) => {
	return {
		type: homepageReducer.SER_REPORTS,
		payload,
	};
};

export {
	setData,
	addInstructor,
	removeInstructor,
	updateInstructor,
	addClass,
	removeClass,
	updateClass,
	setClasses,
	setInstructors,
	setReports,
};
