import { confirmAlert } from 'react-confirm-alert';
import dateFormat from 'dateformat';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ALSABBAGH_USER = 'ALSABBAGH_USER';
const ALSABBAGH_TOKEN = 'ALSABBAGH_TOKEN';
const AUTO_LOGOUT = 'AUTO_LOGOUT';
const STUDENT_PAGE = 'STUDENT_PAGE';
const CLASS = 'class';
const SECTION = 'section';
const NORMAL_LINK = 'NORMAL_LINK';

const ERROR_MSG = 'error';
const SUCCESS_MSG = 'success';
const SERVER_ERROR_MSG = 'حدث خطأ ما! الرجاء المحاولة مرة أخرى';
const FILL_ALL_FIELDS = '!الرجاء ادخال كافة المعلومات';
const ADDED_SUCCESSFULLY = '!تمت الإضافة بنجاح';
const REMOVED_SUCCESSFULLY = '!تم الحذف بنجاح';
const UPDATED_SUCCESSFULLY = '!تم التعديل بنجاح';
const SENT_SUCCESSFULLY = '!تم الارسال بنجاح';
const NO_USERS = 'الرجاء اختيار الطلاب';
const VALIDATE_DESCRIPTION = `الرجاء ادخال الموعد بشكل دقيق 
اليوم: الوقت`;

const RED = 'red_';
const YELLOW = 'yellow_';
const BLUE = 'blue_';
const BROWN = 'brown_';

const PAGINATION = 50;

const setUser = (user) => {
	localStorage.setItem(ALSABBAGH_USER, JSON.stringify(user));
};

const setToken = (token) => {
	localStorage.setItem(ALSABBAGH_TOKEN, token);
};

const setAutoLogout = (time) => {
	localStorage.setItem(AUTO_LOGOUT, time);
};

const getUser = () => {
	const user = localStorage.getItem(ALSABBAGH_USER);
	if (user) return JSON.parse(user);
	return null;
};

const getToken = () => {
	const token = localStorage.getItem(ALSABBAGH_TOKEN);
	if (token) return token;
	return null;
};

const getAutoLogout = () => {
	const time = localStorage.getItem(AUTO_LOGOUT);
	if (time) return time;
	return null;
};

const removeUser = () => {
	localStorage.clear();
};

const formatDateTime = (date) => {
	return dateFormat(date, 'dd/mm/yyyy - h:MM TT');
};

const formatDate = (date) => {
	return dateFormat(date, 'dd/mm/yyyy');
};

const confirmDialog = (content, callback) => {
	confirmAlert({
		title: 'تأكيد الحذف',
		message: `متأكد من حذف ${content}؟`,
		buttons: [
			{
				label: 'تأكيد',
				onClick: () => {
					callback(true);
				},
			},
			{
				label: 'إلغاء',
				onClick: () => {
					callback(false);
				},
			},
		],
	});
};

const confirmLogoutDialog = (callback) => {
	confirmAlert({
		title: 'تسجيل الخروج',
		message: `متأكد من تسجيل الخروج؟`,
		buttons: [
			{
				label: 'تأكيد',
				onClick: () => {
					callback(true);
				},
			},
			{
				label: 'إلغاء',
				onClick: () => {
					callback(false);
				},
			},
		],
	});
};

const setSessionStorage = (data) => {
	sessionStorage.setItem(STUDENT_PAGE, JSON.stringify(data));
};

const getSessionStorage = () => {
	const res = sessionStorage.getItem(STUDENT_PAGE);
	if (res) return JSON.parse(res);
	return null;
};

export {
	ERROR_MSG,
	SUCCESS_MSG,
	RED,
	YELLOW,
	BLUE,
	BROWN,
	SERVER_ERROR_MSG,
	FILL_ALL_FIELDS,
	ADDED_SUCCESSFULLY,
	REMOVED_SUCCESSFULLY,
	UPDATED_SUCCESSFULLY,
	PAGINATION,
	NORMAL_LINK,
	CLASS,
	SECTION,
	SENT_SUCCESSFULLY,
	NO_USERS,
	VALIDATE_DESCRIPTION,
	AUTO_LOGOUT,
	setSessionStorage,
	getSessionStorage,
	confirmLogoutDialog,
	formatDateTime,
	setAutoLogout,
	getAutoLogout,
	setUser,
	setToken,
	getUser,
	getToken,
	removeUser,
	confirmDialog,
	formatDate,
};
