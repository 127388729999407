import { createContext } from 'react';

const UsersContext = createContext({
  users: {
    users: [],
    count: 0,
  },
  selectedUser: '',
  selectedUsers: [],
  dispatch: () => {},
});

export { UsersContext };
