import { SUCCESS_MSG } from '../common';

const SHOW_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';

const initState = {
  isVisible: false,
  type: SUCCESS_MSG,
  message: '',
};

const reducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case SHOW_ALERT: {
      const { payload } = action;

      return {
        ...state,
        isVisible: true,
        type: payload.type,
        message: payload.message,
      };
    }

    case HIDE_ALERT: {
      return {
        ...state,
        isVisible: false,
      };
    }

    default:
      throw new Error(`${type} is not defined in alert reducer`);
  }
};

export { SHOW_ALERT, HIDE_ALERT, initState, reducer };
