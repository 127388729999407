import React from 'react';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body1" color="primary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.linkedin.com/in/taha-kassar97" target="_blank">
        Eng. Taha Kassar
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Footer() {
  return (
    <div className="bg">
      <div className="mt">
        <Copyright />
      </div>
    </div>
  );
}
