const SET_COURSES = 'SET_COURSES';
const ADD_COURSE = 'ADD_COURSE';
const REMOVE_COURSE = 'REMOVE_COURSE';
const EDIT_COURSE = 'EDIT_COURSE';
const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
const UNSELECTED_COURSE = 'UNSELECTED_COURSE';

const state = {
	courses: [],
	selectedCourse: '',
};

const reducer = (state_ = state, action) => {
	const { type } = action;
	switch (type) {
		case SET_COURSES: {
			const { payload } = action;
			return {
				...state_,
				courses: payload,
			};
		}
		case ADD_COURSE: {
			const { payload } = action;
			const courses = [...state_.courses, payload];
			return {
				...state_,
				courses,
			};
		}
		case REMOVE_COURSE: {
			const { payload } = action;
			const courses = [...state_.courses].filter((course) => course.id !== payload);
			return {
				...state_,
				courses,
			};
		}
		case EDIT_COURSE: {
			const { payload } = action;
			const { id } = payload;
			const courses = [...state_.courses];
			const index = courses.findIndex((course) => course.id === id);
			courses.splice(index, 1, payload);
			return {
				...state_,
				courses,
			};
		}
		case SET_SELECTED_COURSE: {
			const { payload } = action;
			return {
				...state_,
				selectedCourse: payload,
			};
		}
		case UNSELECTED_COURSE: {
			return {
				...state_,
				selectedCourse: '',
			};
		}
		default: {
			throw new Error(`${type} is not defined in courses reducer`);
		}
	}
};

export { SET_COURSES, ADD_COURSE, REMOVE_COURSE, EDIT_COURSE, SET_SELECTED_COURSE, UNSELECTED_COURSE, state, reducer };
