import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

export default function EditDialog({ setOpen, handleChange, handleClick, state }) {
	const handleClose = () => {
		setOpen();
	};

	return (
		<>
			<Dialog open={state.openPasswordDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
				<Grid container direction="row" justify="center">
					<DialogTitle id="form-dialog-title">تعديل كلمة المرور</DialogTitle>
				</Grid>
				<DialogContent style={{ height: '180px' }}>
					<Grid container direction="row" justify="center">
						<Avatar>
							<LockOpenRoundedIcon />
						</Avatar>
					</Grid>
					<form>
						<TextField
							variant="filled"
							margin="dense"
							label="كلمة المرور القديمة"
							name="oldPassword"
							value={state.oldPassword}
							onChange={handleChange}
							inputProps={{
								autoComplete: 'none',
							}}
							type="text"
							fullWidth
						/>
						<TextField
							variant="filled"
							margin="dense"
							label="كلمة المرور الجديدة"
							name="newPassword"
							inputProps={{
								autoComplete: 'new-password',
							}}
							onChange={handleChange}
							value={state.newPassword}
							type="text"
							fullWidth
						/>
					</form>
				</DialogContent>
				<DialogActions style={{ marginRight: '25px' }}>
					<Button onClick={handleClose} color="primary" variant="outlined">
						إلغاء
					</Button>
					<Button onClick={handleClick} color="primary" variant="contained">
						تعديل
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
