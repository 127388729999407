import React, { Suspense, useReducer } from 'react';
import { HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Routing from './pages/router/index';
import {
	UserContext,
	HomepageContext,
	UsersContext,
	CoursesContext,
	SectionsContext,
	QuizzesContext,
	AlertProvider,
} from './helpers/contexts';
import {
	userReducer,
	homepageReducer,
	usersReducer,
	coursesReducer,
	sectionsReducer,
	quizzesReducer,
} from './helpers/reducers';

import { Progress } from './components/progress/AppProgress';
import { NewAlert } from './components/alert';

const appTheme = createTheme({
	palette: {
		primary: {
			main: '#1F7CB5',
			dark: '#1E2B4E',
			light: '#98C2DD',
		},
		secondary: {
			main: '#A8774C',
			light: '#271E1E',
			contrastText: '#EDE4DB',
		},
		warning: {
			main: '#DE9D3C',
			light: '#de9d3c79',
		},
		error: {
			main: '#b71c1c',
			light: '#e53935',
		},
	},
	typography: {
		fontFamily: 'Cairo',
	},
});

function UserProvider() {
	const [state, dispatch] = useReducer(userReducer.reducer, userReducer.state);

	return (
		<UserContext.Provider
			value={{
				dispatch,
				token: state.token,
				user: state.user,
			}}
		>
			<Routing />
		</UserContext.Provider>
	);
}

function HomepageDataProvider() {
	const [state, dispatch] = useReducer(homepageReducer.reducer, homepageReducer.state);
	return (
		<HomepageContext.Provider
			value={{
				instructors: state.instructors,
				reports: state.reports,
				classes: state.classes,
				quantity: state.quantity,
				dispatch,
			}}
		>
			<UserProvider />
		</HomepageContext.Provider>
	);
}

function UsersProvider() {
	const [state, dispatch] = useReducer(usersReducer.reducer, usersReducer.state);
	return (
		<UsersContext.Provider
			value={{
				dispatch,
				users: state.users,
				selectedUser: state.selectedUser,
				selectedUsers: state.selectedUsers,
			}}
		>
			<HomepageDataProvider />
		</UsersContext.Provider>
	);
}

function CoursesProvider() {
	const [state, dispatch] = useReducer(coursesReducer.reducer, coursesReducer.state);
	return (
		<CoursesContext.Provider
			value={{
				dispatch,
				courses: state.courses,
				selectedCourse: state.selectedCourse,
			}}
		>
			<UsersProvider />
		</CoursesContext.Provider>
	);
}

function SectionsProvider() {
	const [state, dispatch] = useReducer(sectionsReducer.reducer, sectionsReducer.state);
	return (
		<SectionsContext.Provider
			value={{
				dispatch,
				sections: state.sections,
				selectedSection: state.selectedSection,
			}}
		>
			<CoursesProvider />
		</SectionsContext.Provider>
	);
}

function QuizzesProvider() {
	const [state, dispatch] = useReducer(quizzesReducer.reducer, quizzesReducer.state);
	return (
		<QuizzesContext.Provider
			value={{
				dispatch,
				quizzes: state.quizzes,
				selectedQuiz: state.selectedQuiz,
			}}
		>
			<SectionsProvider />
		</QuizzesContext.Provider>
	);
}

function App() {
	return (
		<div>
			<Suspense
				fallback={
					<div className="base_loader">
						<Progress />
					</div>
				}
			>
				<ThemeProvider theme={appTheme}>
					<HashRouter>
						<AlertProvider>
							<NewAlert />
							<QuizzesProvider />
						</AlertProvider>
					</HashRouter>
				</ThemeProvider>
			</Suspense>
		</div>
	);
}

export default App;
