import { userReducer } from '../reducers';

const setUser = (payload) => {
	return {
		type: userReducer.SET_USER,
		payload,
	};
};

const removeUser = () => {
	return {
		type: userReducer.REMOVE_USER,
	};
};

export { setUser, removeUser };
