import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useAlert } from '../contexts';
import { getConfig } from './services';
import { showAlert } from '../actions/alert';
import { ERROR_MSG, SUCCESS_MSG, UPDATED_SUCCESSFULLY } from '../common';

export function useUpdate({ url, handleLoading, doBack }) {
	const { dispatch } = useAlert();
	const { goBack } = useHistory();

	const callback = useCallback(
		async (body, showMessage = true) => {
			try {
				handleLoading?.(true);

				const result = await axios.put(url, body || {}, getConfig());

				handleLoading?.(false);

				if (showMessage)
					dispatch(
						showAlert({
							message: UPDATED_SUCCESSFULLY,
							type: SUCCESS_MSG,
						})
					);

				if (doBack) goBack();

				return {
					data: result.data.data,
					code: 201,
				};
			} catch (error) {
				handleLoading?.(false);

				dispatch(showAlert(ERROR_MSG));

				return {
					data: null,
					code: error?.response?.status,
				};
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[url]
	);

	return {
		callback,
	};
}
