import React, { useContext, useMemo, useState, useEffect, Children } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import HomeIcon from '@material-ui/icons/Home';
import SubjectIcon from '@material-ui/icons/Subject';
import NotificationsIcon from '@material-ui/icons/Notifications';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import GroupIcon from '@material-ui/icons/Group';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LockIcon from '@material-ui/icons/Lock';
import LoadIcon from '@material-ui/icons/Loop';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PhotoIcon from '@material-ui/icons/Photo';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Badge, Button, Menu } from '@material-ui/core';

import ChangePassword from '../dialogs/ChangePassword';
import { Progress } from '../progress/AppProgress';
import { UserContext } from '../../helpers/contexts';
import { userActions } from '../../helpers/actions';
import { updatePassword } from '../../helpers/server/services';
import { removeUser, confirmLogoutDialog, formatDateTime } from '../../helpers/common';
import { GET_NOTIFICATIONS, UPDATE_NOTIFICATIONS, useGet, useUpdate } from '../../helpers';

const drawerWidth = 240;

const pages = [
	{
		name: 'الرئيسية',
		icon: <HomeIcon />,
		to: '/',
	},
	{
		name: 'المواد',
		icon: <SubjectIcon />,
		to: '/courses',
	},
	{
		name: 'الشعب',
		icon: <BookmarksIcon />,
		to: '/sections',
	},
	{
		name: 'الطلاب',
		icon: <GroupIcon />,
		to: '/students',
	},
	{
		name: 'الاختبارات',
		icon: <AssignmentIcon />,
		to: '/quizzes',
	},
	{
		name: 'القصص',
		icon: <PhotoIcon />,
		to: '/stories',
	},
	{
		name: 'المخططات البيانية',
		icon: <EqualizerIcon />,
		to: '/charts',
	},
];

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(0) + 1,
		[theme.breakpoints.up('md')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	flex: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	flex_center: {
		display: 'flex',
		justifyContent: 'center',
	},
	m_t: {
		marginTop: '20px',
	},
	header: {
		width: '13.2rem',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	right_header: {
		width: '100%',
		display: 'flex',
		gap: '0 30px',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	small_logo: {
		width: '50px',
		height: '50px',
	},
	large_logo: {
		width: '120px',
		height: '120px',
	},
	active_bg: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: '5px',
		margin: '0 5px',
		color: 'white',
		boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.2)',
	},
	white_color: {
		color: 'white',
	},
	online: {
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		background: '#6BDD9A',
		position: 'absolute',
		top: '0',
		right: '-2px',
	},
	relative: {
		position: 'relative',
	},
	notificationItem: {
		display: 'flex',
		flexDirection: 'column',
		gap: '10px',
		padding: '8px',
		borderRadius: '8px',
		marginTop: '10px',
	},
	notificationItemWithBg: {
		display: 'flex',
		flexDirection: 'column',
		gap: '10px',
		background: 'rgba(0, 0, 0, 0.05)',
		padding: '8px',
		borderRadius: '8px',
		marginTop: '10px',
	},
}));

const NotificationsMenu = () => {
	const classes = useStyles();
	const [notificationsMenu, setNotificationsMenu] = useState(null);
	const [unreadCount, setUnread] = useState(0);
	const [page, setPage] = useState(1);
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleLoading = (isLoading) => {
		setLoading(isLoading);
	};

	const { callback, data } = useGet({
		handleLoading,
		url: GET_NOTIFICATIONS,
	});

	const { callback: updateCallback } = useUpdate({
		url: UPDATE_NOTIFICATIONS,
	});

	useEffect(() => {
		callback(page);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (data?.rows && notifications.length === 0) {
			let counter = 0;

			data.rows.forEach((item) => {
				if (!item.isRead) counter++;
			});

			if (counter < 10) {
				setUnread(counter);
			}

			if (counter >= 10) {
				setUnread('9+');
			}
		}

		if (data?.rows) {
			setNotifications((prevState) => [...prevState, ...data?.rows]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const openNotificationsMenu = (e) => {
		if (unreadCount) {
			updateCallback('', false);
		}

		setUnread((prevState) => {
			if (prevState > 0) {
				return 0;
			}
		});

		setNotificationsMenu(e.currentTarget);
	};

	const closeNotificationsMenu = () => {
		setNotificationsMenu(null);
	};

	const handlePage = () => {
		setPage((prevState) => prevState + 1);
	};

	return (
		<>
			<IconButton
				aria-controls="simple-menu"
				color="inherit"
				aria-haspopup="true"
				title="الاشعارات"
				onClick={openNotificationsMenu}
			>
				<Badge badgeContent={unreadCount} color="secondary">
					<NotificationsIcon />
				</Badge>
			</IconButton>

			<Menu
				id="simple-menu"
				anchorEl={notificationsMenu}
				keepMounted
				open={Boolean(notificationsMenu)}
				onClose={closeNotificationsMenu}
				PaperProps={{
					style: {
						display: 'flex',
						flexDirection: 'column',
						maxHeight: '25rem',
						padding: '0 15px 15px 10px',
						width: '24rem',
					},
				}}
			>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.m_t}
					onClick={() => {
						setNotifications([]);
						callback(1);
					}}
				>
					تحديث
					<LoadIcon style={{ marginLeft: '5px' }} />
				</Button>

				{loading && <Progress />}

				{!loading &&
					Children.toArray(
						notifications.map((notification) => (
							<Link
								to={{
									pathname: '/student',
									search: `?student=${notification?.userId}`,
								}}
								className={
									notification.isRead ? classes.notificationItem : classes.notificationItemWithBg
								}
								onClick={closeNotificationsMenu}
							>
								<Typography variant="body1" style={{ textAlign: 'end' }}>
									{notification.title}
								</Typography>
								<Typography variant="body2" color="secondary" style={{ textAlign: 'end' }}>
									{formatDateTime(new Date(notification.createdAt))}
								</Typography>
							</Link>
						))
					)}

				{!loading && notifications.length > 0 && data.count > notifications.length && (
					<Button variant="outlined" color="secondary" className={classes.m_t} onClick={handlePage}>
						تحميل المزيد
						<LoadIcon style={{ marginLeft: '5px' }} />
					</Button>
				)}
			</Menu>
		</>
	);
};

export default function MainSidebar() {
	const classes = useStyles();
	const theme = useTheme();
	const { push } = useHistory();
	const { pathname } = useLocation();
	const { dispatch } = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [state, setState] = useState({
		newPassword: '',
		oldPassword: '',
		openPasswordDialog: false,
	});

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleTogglePasswordDialog = () => {
		setState((prevState) => ({
			...prevState,
			openPasswordDialog: !prevState.openPasswordDialog,
		}));
	};

	const handleChange = ({ target }) => {
		setState((prevState) => ({
			...prevState,
			[target.name]: target.value,
		}));
	};

	const handleClickItem = (to) => {
		handleDrawerClose();

		push(to);
	};

	const handleLogout = () => {
		handleDrawerClose();
		confirmLogoutDialog((callback) => {
			if (callback) {
				dispatch(userActions.removeUser());
				removeUser();
				push('/login');
			}
		});
	};

	const handleChangePassword = async () => {
		handleDrawerClose();
		if (!state.oldPassword || !state.newPassword) {
			alert('الرجاء ادخال كلمة المرور القديمة والجديدة');
			return;
		}
		const data = {
			oldPassword: state.oldPassword,
			newPassword: state.newPassword,
		};
		const res = await updatePassword(data);
		if (res === 422) {
			alert('كلمة المرور القديمة غير صحيحة!');
			return;
		}
		if (res !== 200) {
			alert('حدث خطأ ما، الرجاء المحاولة لاحقاً');
			return;
		}
		setState({
			newPassword: '',
			oldPassword: '',
			openPasswordDialog: false,
		});
		alert('تم التعديل بنجاح!');
	};

	const options = useMemo(() => {
		const data = [
			{
				name: 'تغيير كلمة المرور',
				icon: <LockIcon />,
				handleClick: handleTogglePasswordDialog,
			},
			{
				name: 'تسجيل الخروج',
				icon: <ExitToAppIcon />,
				handleClick: handleLogout,
			},
		];
		return data;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ChangePassword
				setOpen={handleTogglePasswordDialog}
				handleChange={handleChange}
				handleClick={handleChangePassword}
				state={state}
			/>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar className={classes.flex}>
					<div className={classes.header}>
						<div className={classes.relative}>
							<AccountCircleIcon /> <sup className={classes.online}></sup>
						</div>
						<Typography variant="h6" noWrap>
							admin@alsabbagh
						</Typography>
					</div>
					<div className={classes.right_header}>
						<NotificationsMenu />

						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="end"
							className={clsx(classes.menuButton, {
								[classes.hide]: open,
							})}
						>
							<MenuIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				anchor="right"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</div>
				<Divider />
				<div className={`${classes.flex_center}`}>
					<img src="/logo_trans.png" alt="" className={open ? classes.large_logo : classes.small_logo} />
				</div>
				<Divider />
				<List>
					{pages.map((page, index) => (
						<li
							key={index}
							to={page.to}
							onClick={() => handleClickItem(page.to)}
							className={page.to === pathname && open ? classes.active_bg : ''}
						>
							<ListItem button>
								<ListItemIcon className={page.to === pathname && open ? classes.white_color : ''}>
									{page.icon}
								</ListItemIcon>
								<ListItemText primary={page.name} />
							</ListItem>
						</li>
					))}
				</List>
				<Divider />
				<List className={classes.m_t}>
					{options.map((option, index) => (
						<ListItem button key={index} onClick={option.handleClick}>
							<ListItemIcon>{option.icon}</ListItemIcon>
							<ListItemText primary={option.name} />
						</ListItem>
					))}
				</List>
			</Drawer>
		</div>
	);
}
