const SET_USER = 'SET_USER';
const REMOVE_USER = 'REMOVE_USER';

const state = {
	user: {},
	token: null,
};

const reducer = (state, action) => {
	const { type } = action;
	switch (type) {
		case SET_USER: {
			const { user, token } = action.payload;
			return {
				user,
				token,
			};
		}
		case REMOVE_USER: {
			return {
				user: {},
				token: null,
			};
		}
		default: {
			throw new Error(`${type} is not defined in user reducer`);
		}
	}
};

export { SET_USER, REMOVE_USER, state, reducer };
