const SET_USERS = 'SET_USERS';
const ADD_USER = 'ADD_USER';
const EDIT_USER = 'EDIT_USER';
const REMOVE_USER = 'REMOVE_USER';
const SET_SELECTED_USER = 'SET_SELECTED_USER';
const UN_SELECT_USER = 'UN_SELECT_USER';
const TOGGLE_USERS = 'TOGGLE_USERS';
const TOGGLE_ALL_USERS = 'TOGGLE_ALL_USERS';
const EMPTY_USERS = 'EMPTY_USERS';

const state = {
	users: {
		users: [],
		count: 0,
	},
	selectedUser: '',
	selectedUsers: [],
};

const reducer = (state_ = state, action) => {
	const { type } = action;
	switch (type) {
		case SET_USERS: {
			const { payload: users } = action;
			return {
				...state_,
				users: {
					users: users.data.rows,
					count: users.data.count,
				},
			};
		}

		case ADD_USER: {
			const { payload: user } = action;
			const users = [...state_.users.users, user];
			return {
				...state_,
				users: {
					count: state_.users.count + 1,
					users,
				},
			};
		}

		case REMOVE_USER: {
			const { payload } = action;
			const users = [...state_.users.users];
			const index = users.findIndex((user) => user.id === payload);
			users.splice(index, 1);
			if (index !== -1) {
				return {
					...state_,
					users: {
						users,
						count: state_.users.count - 1,
					},
				};
			}
			return {
				...state_,
			};
		}

		case SET_SELECTED_USER: {
			const { payload: selectedUser } = action;
			return {
				...state_,
				selectedUser,
			};
		}

		case UN_SELECT_USER: {
			return {
				...state_,
				selectedUser: '',
			};
		}

		case EDIT_USER: {
			const { payload } = action;
			const { id } = payload;
			const users = [...state_.users.users];
			const index = users.findIndex((user) => user.id === id);
			users.splice(index, 1, payload);
			return {
				...state_,
				users: {
					count: state_.users.count,
					users,
				},
			};
		}

		case TOGGLE_USERS: {
			const { payload: id } = action;
			const users = [...state_.selectedUsers];
			const index = users.findIndex((user) => user.userId === id);
			if (index === -1) {
				users.push({ userId: id });
				return {
					...state_,
					selectedUsers: users,
				};
			}
			users.splice(index, 1);
			return {
				...state_,
				selectedUsers: users,
			};
		}

		case TOGGLE_ALL_USERS: {
			const { payload: ids } = action;

			if (ids.length > 0) {
				const users = ids.map((id) => ({
					userId: id,
				}));

				return {
					...state_,
					selectedUsers: users,
				};
			}

			return {
				...state_,
				selectedUsers: [],
			};
		}

		case EMPTY_USERS: {
			return {
				...state_,
				selectedUsers: [],
			};
		}

		default: {
			throw new Error(`${type} is not defined in users reducer`);
		}
	}
};

export {
	SET_USERS,
	ADD_USER,
	EDIT_USER,
	REMOVE_USER,
	SET_SELECTED_USER,
	UN_SELECT_USER,
	TOGGLE_USERS,
	EMPTY_USERS,
	TOGGLE_ALL_USERS,
	state,
	reducer,
};
