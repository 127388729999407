import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';

export default class ErrorHandler extends Component {
  state = {
    error: false,
  };

  componentDidCatch() {
    this.setState({
      error: true,
    });
  }
  render() {
    if (this.state.error)
      return (
        <Container style={{ height: '100vh', width: '100vw' }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <PermScanWifiIcon style={{ fontSize: '200px' }} />
            </Grid>
            <Grid xs={12}>
              <Typography variant="h3">Oops!</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="h4">Something wrong happened</Typography>
            </Grid>
          </Grid>
        </Container>
      );
    return this.props.children;
  }
}
