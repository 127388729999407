const SET_DATA = 'SET_DATA';

const SET_INSTRUCTORS = 'SET_INSTRUCTORS';
const SET_CLASSES = 'SET_CLASSES';
const SER_REPORTS = 'SER_REPORTS';
const ADD_INSTRUCTOR = 'ADD_INSTRUCTOR';
const REMOVE_INSTRUCTOR = 'REMOVE_INSTRUCTOR';
const UPDATE_INSTRUCTOR = 'UPDATE_INSTRUCTOR';

const ADD_CLASS = 'ADD_CLASS';
const REMOVE_CLASS = 'REMOVE_CLASS';
const UPDATE_CLASS = 'UPDATE_CLASS';

const state = {
	quantity: {
		instructors: 0,
		classes: 0,
		users: 0,
		reports: 0,
	},
	instructors: [],
	reports: [],
	classes: [],
};

const reducer = (state_ = state, action) => {
	const { type } = action;
	switch (type) {
		case SET_DATA: {
			const { data } = action.payload;
			const quantity = {
				instructors: data.instructors.count,
				classes: data.classes.count,
				users: data.users,
				reports: data.reports.count,
			};
			const instructors = data.instructors.rows;
			const reports = data.reports.rows;
			const classes = data.classes.rows;
			return {
				quantity,
				reports,
				instructors,
				classes,
			};
		}

		case ADD_INSTRUCTOR: {
			const { payload } = action;
			const instructors = [...state_.instructors, payload];
			return {
				...state_,
				instructors,
			};
		}

		case REMOVE_INSTRUCTOR: {
			const { payload } = action;
			const instructors = [...state_.instructors];
			const newInstructors = instructors.filter((instructor) => instructor.id !== payload);
			return {
				...state_,
				quantity: {
					...state_.quantity,
					instructors: state_.quantity.instructors - 1,
				},
				instructors: newInstructors,
			};
		}

		case UPDATE_INSTRUCTOR: {
			const { payload } = action;
			const instructors = [...state_.instructors];
			const index = instructors.findIndex((instructor) => instructor.id === payload.id);
			instructors.splice(index, 1, payload);
			return {
				...state_,
				instructors,
			};
		}

		case ADD_CLASS: {
			const { payload } = action;
			const classes = [...state_.classes, payload];
			return {
				...state_,
				classes,
			};
		}

		case REMOVE_CLASS: {
			const { payload } = action;
			const classes = [...state_.classes];
			const newClasses = classes.filter((class_) => class_.id !== payload);
			return {
				...state_,
				quantity: {
					...state_.quantity,
					classes: state_.quantity.classes - 1,
				},
				classes: newClasses,
			};
		}

		case UPDATE_CLASS: {
			const { payload } = action;
			const classes = [...state_.classes];
			const index = classes.findIndex((class_) => class_.id === payload.id);
			classes.splice(index, 1, payload);
			return {
				...state_,
				classes,
			};
		}

		case SET_CLASSES: {
			const { payload } = action;
			return {
				...state_,
				classes: payload.rows,
				quantity: {
					...state_.quantity,
					classes: payload.count,
				},
			};
		}

		case SET_INSTRUCTORS: {
			const { payload } = action;
			return {
				...state_,
				instructors: payload.rows,
				quantity: {
					...state_.quantity,
					instructors: payload.count,
				},
			};
		}

		case SER_REPORTS: {
			const { payload } = action;
			return {
				...state_,
				reports: payload,
				quantity: {
					...state_.quantity,
					reports: payload.length,
				},
			};
		}

		default: {
			throw new Error(`${type} is not defined in homepage reducer`);
		}
	}
};

export {
	SET_DATA,
	ADD_INSTRUCTOR,
	REMOVE_INSTRUCTOR,
	UPDATE_INSTRUCTOR,
	ADD_CLASS,
	REMOVE_CLASS,
	UPDATE_CLASS,
	SET_CLASSES,
	SET_INSTRUCTORS,
	SER_REPORTS,
	state,
	reducer,
};
