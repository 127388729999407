const SET_SECTIONS = 'SET_SECTIONS';
const SET_SECTION = 'SET_SECTION';
const UNSET_SECTION = 'UNSET_SECTION';
const ADD_SECTION = 'ADD_SECTION';
const REMOVE_SECTION = 'REMOVE_SECTION';
const EDIT_SECTION = 'EDIT_SECTION';

const state = {
	sections: [],
	selectedSection: '',
};

const reducer = (state_ = state, action) => {
	const { type } = action;
	switch (type) {
		case SET_SECTIONS: {
			return {
				...state_,
				sections: action.payload,
			};
		}
		case ADD_SECTION: {
			const { payload } = action;
			const sections = [...state_.sections, payload];
			return {
				...state_,
				sections,
			};
		}
		case SET_SECTION: {
			return {
				...state_,
				selectedSection: action.payload,
			};
		}
		case UNSET_SECTION: {
			return {
				...state_,
				selectedSection: '',
			};
		}
		case REMOVE_SECTION: {
			const { payload: id } = action;
			const sections = [...state_.sections].filter((section) => section.id !== id);
			return {
				...state_,
				sections,
			};
		}
		case EDIT_SECTION: {
			const { payload } = action;
			const { id } = payload;
			const sections = [...state_.sections];
			const index = sections.findIndex((section) => section.id === id);
			sections.splice(index, 1, payload);
			return {
				...state_,
				sections,
			};
		}
		default: {
			throw new Error(`${type} is not defined in sections reducer`);
		}
	}
};

export { SET_SECTIONS, ADD_SECTION, REMOVE_SECTION, SET_SECTION, EDIT_SECTION, UNSET_SECTION, state, reducer };
