import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useAlert } from '../../helpers/contexts';
import { hideAlert } from '../../helpers/actions/alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AppAlert() {
	const { isVisible, type, message, dispatch } = useAlert();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(hideAlert());
	};

	return (
		<Snackbar open={isVisible} autoHideDuration={6000} onClose={handleClose}>
			<Alert onClose={handleClose} severity={type}>
				{message}
			</Alert>
		</Snackbar>
	);
}
