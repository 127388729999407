import { createContext } from 'react';

const HomepageContext = createContext({
	quantity: {
		instructors: 0,
		classes: 0,
		users: 0,
		reports: 0,
	},
	instructors: [],
	reports: [],
	classes: [],
	dispatch: () => {},
});

export { HomepageContext };
