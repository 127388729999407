import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { getConfig } from './services';
import { useAlert } from '../contexts';
import { showAlert } from '../actions/alert';
import { ERROR_MSG } from '../common';

export function useDelete({ url, handleLoading, doBack }) {
  const { dispatch } = useAlert();
  const { goBack } = useHistory();

  const callback = useCallback(
    async (id) => {
      try {
        handleLoading(true);

        await axios.delete(`${url}/${id}`, getConfig());

        handleLoading(false);

        if (doBack) goBack();

        return true;
      } catch (error) {
        handleLoading(false);

        dispatch(showAlert(ERROR_MSG));

        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  return {
    callback,
  };
}
