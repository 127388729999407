import { usersReducer } from '../reducers';

const setUsers = (payload) => {
	return {
		type: usersReducer.SET_USERS,
		payload,
	};
};

const addUser = (payload) => {
	return {
		type: usersReducer.ADD_USER,
		payload,
	};
};

const removeUser = (payload) => {
	return {
		type: usersReducer.REMOVE_USER,
		payload,
	};
};

const setSelectedUser = (payload) => {
	return {
		type: usersReducer.SET_SELECTED_USER,
		payload,
	};
};

const unSelectedUser = (payload) => {
	return {
		type: usersReducer.UN_SELECT_USER,
		payload,
	};
};

const updateUser = (payload) => {
	return {
		type: usersReducer.EDIT_USER,
		payload,
	};
};

const toggleUsers = (payload) => {
	return {
		type: usersReducer.TOGGLE_USERS,
		payload,
	};
};

const toggleAllUsers = (payload) => {
	return {
		type: usersReducer.TOGGLE_ALL_USERS,
		payload,
	};
};

const emptyUsers = () => {
	return {
		type: usersReducer.EMPTY_USERS,
	};
};

export {
	setUsers,
	addUser,
	removeUser,
	setSelectedUser,
	unSelectedUser,
	updateUser,
	toggleUsers,
	emptyUsers,
	toggleAllUsers,
};
