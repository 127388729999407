import axios from 'axios';
import {
	LOGIN,
	INSTRUCTOR,
	ADD_INSTRUCTOR,
	REMOVE_INSTRUCTOR,
	UPDATE_INSTRUCTOR,
	ADD_CLASS,
	UPDATE_CLASS,
	REMOVE_CLASS,
	USERS,
	ADD_USER,
	CLASS,
	REMOVE_USER,
	UPDATE_USER,
	COURSES,
	ADD_COURSE,
	REMOVE_COURSE,
	UPDATE_COURSE,
	ADD_SECTION,
	REMOVE_SECTION,
	UPDATE_SECTION,
	SECTIONS,
	USER_SECTIONS,
	TESTS,
	ADD_TEST,
	REMOVE_TEST,
	UPDATE_TEST,
	MARKS,
	MARK,
	UPDATE_MARK,
	ADD_NOTIFICATIONS_TO_STUDENTS,
	ADD_NOTIFICATIONS_TO_SECTION,
	UPDATE_PASSWORD,
	USER_MARKS,
	GET_INSTRUCTORS,
} from './APIs';
import { getToken } from '../common';

export const getConfig = () => {
	return {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: `Bearer ${getToken()}`,
		},
	};
};

const login = async (data) => {
	try {
		const res = await axios.post(LOGIN, data);
		return {
			data: res.data,
			code: 200,
		};
	} catch (error) {
		console.info(error.response);
		return {
			code: error?.response?.status,
		};
	}
};

const updatePassword = async (data) => {
	try {
		const res = await axios.put(UPDATE_PASSWORD, data, getConfig());
		return res.status;
	} catch (error) {
		console.log(error.response);
		return error?.response?.status;
	}
};

const getHomepageData = async () => {
	try {
		const res = await axios.get(INSTRUCTOR, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getInstructors = async () => {
	try {
		const res = await axios.get(GET_INSTRUCTORS, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addInstructor = async (data) => {
	try {
		const res = await axios.post(ADD_INSTRUCTOR, data, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeInstructor = async (id) => {
	try {
		await axios.delete(`${REMOVE_INSTRUCTOR}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateInstructor = async (data) => {
	try {
		await axios.put(UPDATE_INSTRUCTOR, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addClass = async (data) => {
	try {
		const res = await axios.post(ADD_CLASS, data, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeClass = async (id) => {
	try {
		await axios.delete(`${REMOVE_CLASS}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateClass = async (data) => {
	try {
		await axios.put(UPDATE_CLASS, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getClasses = async () => {
	try {
		const res = await axios.get(CLASS, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getUsers = async (page, name = '', classId = '', sectionId = '') => {
	try {
		const res = await axios.get(
			`${USERS}?pageNumber=${page}&name=${name}&classId=${classId}&sectionId=${sectionId}`,
			getConfig()
		);
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addUser = async (data) => {
	try {
		const res = await axios.post(ADD_USER, data, getConfig());
		return {
			data: res.data.data,
			ok: true,
		};
	} catch (error) {
		console.info(error.response);
		if (error?.response?.status === 409) {
			return {
				ok: false,
			};
		}
		return {
			ok: null,
		};
	}
};

const removeUser = async (id) => {
	try {
		await axios.delete(`${REMOVE_USER}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateUser = async (data) => {
	try {
		await axios.put(UPDATE_USER, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getClassesAndUsers = async (classId = '', sectionId = '') => {
	try {
		const [users, classes] = await Promise.all([
			axios.get(`${USERS}?pageNumber=${1}&classId=${classId}&sectionId=${sectionId}`, getConfig()),
			axios.get(CLASS, getConfig()),
		]);
		return [users.data, classes.data];
	} catch (error) {
		console.info(error.response);
		return [null, null];
	}
};

const getCourses = async (classId = '') => {
	try {
		const res = await axios.get(`${COURSES}?class=${classId}`, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addCourse = async (data) => {
	try {
		const res = await axios.post(ADD_COURSE, data, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeCourse = async (id) => {
	try {
		await axios.delete(`${REMOVE_COURSE}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateCourse = async (data) => {
	try {
		await axios.put(UPDATE_COURSE, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getSections = async (course = '') => {
	try {
		const res = await axios.get(`${SECTIONS}?course=${course}`, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addSection = async (data) => {
	try {
		const res = await axios.post(ADD_SECTION, data, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeSection = async (id) => {
	try {
		await axios.delete(`${REMOVE_SECTION}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateSection = async (data) => {
	try {
		await axios.put(UPDATE_SECTION, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addUserSection = async (data) => {
	try {
		const res = await axios.post(USER_SECTIONS, data, getConfig());
		return {
			code: 201,
			data: res.data,
		};
	} catch (error) {
		console.info(error.response);

		if (error.response.data.data.includes('Section is full')) {
			return {
				code: error?.response?.status,
				data: 'الشعبة ممتلئة',
			};
		}
		return {
			code: error?.response?.status,
			data: 'الطالب موجود في الشعبة مسبقاً',
		};
	}
};

const getUserSections = async (userId) => {
	try {
		const res = await axios.get(`${USER_SECTIONS}/${userId}`, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeUserSection = async (id) => {
	try {
		await axios.delete(`${USER_SECTIONS}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const addTest = async (data) => {
	try {
		const res = await axios.post(ADD_TEST, data, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const removeTest = async (id) => {
	try {
		await axios.delete(`${REMOVE_TEST}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getTests = async (sectionId = '', courseId = '', title = '') => {
	try {
		const res = await axios.get(`${TESTS}/?course=${courseId}&section=${sectionId}&title=${title}`, getConfig());
		return res.data;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const updateTest = async (data) => {
	try {
		await axios.put(UPDATE_TEST, data, getConfig());
		return true;
	} catch (error) {
		console.info(error.response);
		return null;
	}
};

const getMarks = async (testId, name = '') => {
	try {
		const res = await axios.get(`${MARKS}?testId=${testId}&name=${name}`, getConfig());
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const removeMark = async (id) => {
	try {
		await axios.delete(`${MARK}/${id}`, getConfig());
		return true;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const updateMark = async (data) => {
	try {
		await axios.put(UPDATE_MARK, data, getConfig());
		return true;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const getUserMarks = async (id, startDate, endDate) => {
	try {
		const res = await axios.get(`${USER_MARKS}?id=${id}&startDate=${startDate}&endDate=${endDate}`, getConfig());
		return res.data;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const addNotificationsToStudents = async (data) => {
	try {
		await axios.post(ADD_NOTIFICATIONS_TO_STUDENTS, data, getConfig());
		return true;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

const addNotificationsToSection = async (data) => {
	try {
		await axios.post(ADD_NOTIFICATIONS_TO_SECTION, data, getConfig());
		return true;
	} catch (error) {
		console.log(error.response);
		return null;
	}
};

export {
	login,
	getHomepageData,
	addInstructor,
	removeInstructor,
	updateInstructor,
	addClass,
	removeClass,
	updateClass,
	getUsers,
	addUser,
	getClasses,
	getClassesAndUsers,
	removeUser,
	updateUser,
	getCourses,
	addCourse,
	removeCourse,
	updateCourse,
	getSections,
	addSection,
	removeSection,
	updateSection,
	addUserSection,
	getUserSections,
	removeUserSection,
	addTest,
	getTests,
	removeTest,
	updateTest,
	getMarks,
	removeMark,
	updateMark,
	addNotificationsToStudents,
	addNotificationsToSection,
	updatePassword,
	getUserMarks,
	getInstructors,
};
